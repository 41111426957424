import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import BlogDetailSection from "../shared/sections/BlogDetailSection";
import useDocumentTitle from "../Hooks/PageTitle";

const BlogDetails = () => {
	useDocumentTitle("TRoo Hotel & Resort | Blog Details")
	return (
		<Layout banner={<SubHeroSection title="Blog Detail" />}>
			<BlogDetailSection />
		</Layout>
	);
};

export default BlogDetails;
