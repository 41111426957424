import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const TeamCard = ({ teamImg, heading, designation }) => {
	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className="w-full flex flex-col items-start overflow-hidden bg-troo-light rounded-lg shadow-2xl group"
		>
			<div className="relative aspect-square overflow-hidden rounded-t-lg w-full">
				<motion.div
					initial={{ y: 100 }}
					whileInView={{ y: 0 }}
					transition={{ type: "spring", stiffness: 100 }}
					className="absolute top-0 left-0 bg-troo-secondary bg-opacity-60 w-full h-full flex-col items-center justify-end p-6 hidden group-hover:flex"
				>
					<div className="flex items-center gap-3">
						<div className="bg-troo-primary text-troo-light p-2 rounded-full">
							<FaFacebookF className="text-base" />
						</div>
						<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
							<FaInstagram className="text-base" />
						</div>
						<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
							<FaXTwitter className="text-base" />
						</div>
						<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
							<FaLinkedinIn className="text-base" />
						</div>
					</div>
				</motion.div>
				<img src={teamImg} alt="team" className="w-full h-full object-cover" />
			</div>
			<div className="w-full bg-troo-light py-7 px-4 flex flex-col items-center gap-3 rounded-b-lg text-center">
				<h5>
					<Link to={ROUTES.teamDetails} className="text-xl">
						{heading}
					</Link>
				</h5>
				<p>{designation}</p>
			</div>
		</motion.div>
	);
};

export default TeamCard;
