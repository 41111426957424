import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

const PricingCard = () => {
	return (
		<div className="w-full border border-troo-primary border-opacity-10 shadow-sm hover:shadow-2xl p-6 lg:p-12 gap-4 rounded-lg flex flex-col items-center">
			<h4>Basic Package</h4>
			<div>
				<span className="text-2xl font-bold font-jost">$650</span> /per Month
			</div>
			<button className="btn-primary w-full">Choose This Plan</button>
			<p>
				Lorem Ipsum is simply dummy text of the printing and typesetting
				industry.
			</p>
			<div className="p-6 lg:p-10 bg-troo-secondary text-troo-light rounded-lg flex flex-col items-start gap-2">
				<div className="flex items-center gap-2">
					<BsCheckCircleFill className="text-troo-primary text-base md:text-lg" />
					<p>100 GB Storage</p>
				</div>
				<div className="flex items-center gap-2">
					<BsCheckCircleFill className="text-troo-primary text-base md:text-lg" />
					<p>Unlimited Photos & Videos</p>
				</div>
				<div className="flex items-center gap-2">
					<BsCheckCircleFill className="text-troo-primary text-base md:text-lg" />
					<p>Exclusive Support</p>
				</div>
				<div className="flex items-center gap-2">
					<BsCheckCircleFill className="text-troo-primary text-base md:text-lg" />
					<p>Custom Branding Strategy</p>
				</div>
				<div className="flex items-center gap-2">
					<BsCheckCircleFill className="text-troo-primary text-base md:text-lg" />
					<p>Advanced Custom Field</p>
				</div>
			</div>
		</div>
	);
};

export default PricingCard;
