import React from "react";
import SubHeroSection from "../shared/sections/SubHeroSection";
import Layout from "../shared/components/Layout";
import FaqListingSection from "../shared/sections/FaqListingSection";
import useDocumentTitle from "../Hooks/PageTitle";

const Faqs = () => {
	useDocumentTitle("TRoo Hotel & Resort | FAQs")
	return (
		<Layout banner={<SubHeroSection title="Most Common FAQ" />}>
			<FaqListingSection />
		</Layout>
	);
};

export default Faqs;
