import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import RoomSection from "../shared/sections/RoomSection";
import useDocumentTitle from "../Hooks/PageTitle";

const SuitsAndRooms = () => {
	useDocumentTitle("TRoo Hotel & Resort | Rooms")
	return (
		<Layout banner={<SubHeroSection title="Luxury Suites & Rooms" />}>
			<RoomSection isListingPage/>
		</Layout>
	);
};

export default SuitsAndRooms;
