import React from "react";
import Accordian from "../components/Accordian";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

const FaqListingSection = () => {
	return (
		<div className="py-[100px] bg-troo-light bg-cover bg-no-repeat bg-center w-full">
			<div className="relative main-container-big h-full gap-20 flex flex-col items-center">
				<div className="w-full items-start grid lg:grid-cols-2 gap-8">
					<div className="w-full flex flex-col items-start gap-4">
						<div className="w-full flex flex-col items-start gap-2">
							<p className="font-medium">Most Ask</p>
							<p className="text-3xl font-bold text-troo-secondary font-jost">
								General Questions
							</p>
						</div>
						<div className="w-full flex flex-col items-start gap-3">
							<Accordian
								isBordered
								title="Where can I find room descriptions?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="What taxes and/or additional charges apply?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Can I get my corporate rate when making a reservation online?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Will my credit card be charged prior to my arrival at the hotel?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Do I need to guarantee my reservation with a credit card?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
						</div>
					</div>
					<div className="w-full flex flex-col items-start gap-4">
						<div className="w-full flex flex-col items-start gap-2">
							<p className="font-medium">People Know</p>
							<p className="text-3xl font-bold text-troo-secondary font-jost">
								Other Questions
							</p>
						</div>
						<div className="w-full flex flex-col items-start gap-3">
							<Accordian
								isBordered
								title="Where can I find room descriptions?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="What taxes and/or additional charges apply?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Can I get my corporate rate when making a reservation online?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Will my credit card be charged prior to my arrival at the hotel?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Do I need to guarantee my reservation with a credit card?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
						</div>
					</div>
					<div className="w-full flex flex-col items-start gap-4">
						<div className="w-full flex flex-col items-start gap-2">
							<p className="font-medium">Most Ask</p>
							<p className="text-3xl font-bold text-troo-secondary font-jost">
								General Questions
							</p>
						</div>
						<div className="w-full flex flex-col items-start gap-3">
							<Accordian
								isBordered
								title="Where can I find room descriptions?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="What taxes and/or additional charges apply?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Can I get my corporate rate when making a reservation online?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Will my credit card be charged prior to my arrival at the hotel?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Do I need to guarantee my reservation with a credit card?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
						</div>
					</div>
					<div className="w-full flex flex-col items-start gap-4">
						<div className="w-full flex flex-col items-start gap-2">
							<p className="font-medium">People Know</p>
							<p className="text-3xl font-bold text-troo-secondary font-jost">
								Other Questions
							</p>
						</div>
						<div className="w-full flex flex-col items-start gap-3">
							<Accordian
								isBordered
								title="Where can I find room descriptions?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="What taxes and/or additional charges apply?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Can I get my corporate rate when making a reservation online?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Will my credit card be charged prior to my arrival at the hotel?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
							<Accordian
								isBordered
								title="Do I need to guarantee my reservation with a credit card?"
								desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col items-center text-center gap-2">
					<p className="text-3xl font-jost text-troo-tertiary font-bold">
						Still you have a questions?
					</p>
					<p className="text-3xl font-jost text-troo-primary font-bold">
						Contact with us
					</p>
					<div className="w-full bg-troo-secondary text-troo-light p-8 rounded-xl flex flex-col md:flex-row md:items-center gap-6 mt-6">
						<div className="flex items-center gap-2">
							<div className="bg-troo-primary bg-opacity-20 text-troo-primary p-3 rounded-lg text-3xl">
								<BsTelephone className="text-xl" />
							</div>
							<div className="flex flex-col items-start gap-1">
								<p className="text-sm">Call Us on</p>
								<p className="font-jost text-xl font-bold">+41 27 486 86 86</p>
							</div>
						</div>
						<div className="flex items-center gap-2">
							<div className="bg-troo-primary bg-opacity-20 text-troo-primary p-3 rounded-lg text-3xl">
								<HiOutlineMail className="text-xl" />
							</div>
							<div className="flex flex-col items-start gap-1">
								<p className="text-sm">Email Us on</p>
								<p className="font-jost text-xl font-bold">
									info@troothemes.com
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FaqListingSection;
