import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
const FacilityCard = ({ heading, description, roomImg }) => {
	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className="w-full p-6 bg-troo-light border-t-2 flex flex-col items-start gap-5 hover:border-troo-primary shadow-xl"
		>
			<div className="w-full flex flex-col items-center justify-center">
				{description}
				<h4>{heading}</h4>
			</div>
			<motion.div
				initial={{ opacity: 0.5 }}
				whileInView={{ opacity: 1 }}
				transition={{ type: "spring", stiffness: 100 }}
				className="relative w-full xl:h-[420px] aspect-square overflow-hidden rounded-lg group"
			>
				<img src={roomImg} alt="room" className="w-full h-full object-cover" />
				<motion.div
					initial={{ scale: 0.8 }}
					whileInView={{ scale: 1 }}
					transition={{ type: "spring", stiffness: 100 }}
					className="absolute top-0 left-0 bg-troo-secondary w-full h-full bg-opacity-75 hidden group-hover:flex flex-col items-center justify-center"
				>
					<Link to={ROUTES.facilityDetails}>
						<button className="p-4 bg-troo-primary rounded-full text-2xl text-troo-light flex items-center justify-center">
							<BsArrowUpRight />
						</button>
					</Link>
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

export default FacilityCard;
