import React from "react";
import { motion } from "framer-motion";

const AdvantageCard = ({ heading, description, icon }) => {
	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className="w-full bg-troo-light p-8 flex flex-col gap-4 items-center hover:shadow-lg border-t-2 border-transparent hover:border-troo-primary"
		>
			<h4 className="text-center">{heading}</h4>
			<p className="text-center">{description}</p>
			<div className="p-3 rounded-full bg-troo-primary bg-opacity-10">
				<img src={icon} alt="icon" />
			</div>
		</motion.div>
	);
};

export default AdvantageCard;
