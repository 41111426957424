import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { motion } from "framer-motion";

const SubHeroSection = ({ title }) => {
	return (
		<div className="py-[100px] h-[400px] w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col justify-start">
				<motion.div
					initial={{ scale: 0.8 }}
					whileInView={{ scale: 1 }}
					transition={{ type: "spring", stiffness: 100 }}
					className="flex flex-col bg-troo-light px-28 py-8 items-center text-center gap-3"
				>
					<Breadcrumbs />
					<h2>{title}</h2>
				</motion.div>
			</div>
		</div>
	);
};

export default SubHeroSection;
