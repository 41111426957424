import React from "react";
import { motion } from "framer-motion";

const Heading = ({ title, headline, alignStart, light }) => {
	return (
		<motion.div
			initial={{ x: 100 }}
			whileInView={{ x: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }}
			className={`flex flex-col gap-5 ${
				alignStart ? "items-start" : "items-center"
			} ${light ? "text-troo-light" : "text-troo-tertiary"}`}
		>
			<div className={`flex gap-3 items-center`}>
				<div className="flex items-center gap-1">
					<div className="w-[4px] h-[4px] rounded-full bg-troo-primary"></div>
					<div className="w-[35px] h-[4px] bg-troo-primary rounded-2xl"></div>
				</div>
				<p className="text-sm lg:text-lg">{headline}</p>
			</div>
			<h2 className={`${alignStart ? "text-start" : "text-center"}`}>
				{title}
			</h2>
		</motion.div>
	);
};

export default Heading;
