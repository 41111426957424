import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import TmImg from "../../assets/images/team/image-2.png";

const TeamDetailSection = () => {
	return (
		<div className="py-[100px] bg-troo-light bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 items-start flex-col">
				<div className="w-full grid grid-cols-1 md:grid-cols-3 gap-10">
					<div className="md:col-span-2 flex flex-col items-start gap-3">
						<h4>Daisy Stracke</h4>
						<p>Hotel Chef</p>
						<div className="w-9 h-[2px] bg-troo-primary" />
						<div className="flex items-center gap-3 justify-end">
							<div className="bg-troo-primary text-troo-light p-2 rounded-full">
								<FaFacebookF className="text-base" />
							</div>
							<div className="hover:bg-troo-primary text-troo-secondary hover:text-troo-light p-2 rounded-full">
								<FaInstagram className="text-base" />
							</div>
							<div className="hover:bg-troo-primary text-troo-secondary hover:text-troo-light p-2 rounded-full">
								<FaXTwitter className="text-base" />
							</div>
							<div className="hover:bg-troo-primary text-troo-secondary hover:text-troo-light p-2 rounded-full">
								<FaLinkedinIn className="text-base" />
							</div>
						</div>
						<div className="flex w-full flex-col items-start gap-4">
							<h3>Language Skill</h3>
							<div className="w-full flex flex-col items-start gap-3">
								<div className="flex w-full items-center justify-between">
									<p className="font-jost text-troo-secondary">English</p>
									<p className="font-jost text-troo-secondary text-xl font-bold">
										95%
									</p>
								</div>
								<div className="w-full h-[7px] bg-troo-primary bg-opacity-5 flex items-center rounded-full">
									<div className="w-[95%] h-[3px] bg-troo-primary rounded-full" />
								</div>
							</div>
							<div className="w-full flex flex-col items-start gap-3">
								<div className="flex w-full items-center justify-between">
									<p className="font-jost text-troo-secondary">Russion</p>
									<p className="font-jost text-troo-secondary text-xl font-bold">
										85%
									</p>
								</div>
								<div className="w-full h-[7px] bg-troo-primary bg-opacity-5 flex items-center rounded-full">
									<div className="w-[85%] h-[3px] bg-troo-primary rounded-full" />
								</div>
							</div>
							<div className="w-full flex flex-col items-start gap-3">
								<div className="flex w-full items-center justify-between">
									<p className="font-jost text-troo-secondary">French</p>
									<p className="font-jost text-troo-secondary text-xl font-bold">
										75%
									</p>
								</div>
								<div className="w-full h-[7px] bg-troo-primary bg-opacity-5 flex items-center rounded-full">
									<div className="w-[75%] h-[3px] bg-troo-primary rounded-full" />
								</div>
							</div>
						</div>
					</div>
					<div className="relative w-full h-full z-10">
						<img
							src={TmImg}
							alt="tmimg"
							className="w-full h-full object-cover"
						/>
						<div className="bg-troo-primary absolute top-2 left-2 w-full h-full -z-10"></div>
					</div>
				</div>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don't look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn't anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat predefined
					chunks as necessary, making this the first true generator on the
					Internet. It uses a dictionary of over 200 Latin words, combined with
					a handful of model sentence structures, to generate Lorem Ipsum which
					looks reasonable. The generated Lorem Ipsum is therefore always free
					from repetition, injected humour, or non-characteristic words etc.
				</p>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don't look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn't anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat predefined
					chunks as necessary, making this the first true generator on the
					Internet. It uses a dictionary of over 200 Latin words, combined with
					a handful of model sentence structures, to generate Lorem Ipsum which
					looks reasonable.
				</p>
			</div>
		</div>
	);
};

export default TeamDetailSection;
