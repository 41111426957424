import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import FacilityDetailSection from "../shared/sections/FacilityDetailSection";
import useDocumentTitle from "../Hooks/PageTitle";

const FacilityDetails = () => {
	useDocumentTitle("TRoo Hotel & Resort | Facilities Details")
	return (
		<Layout banner={<SubHeroSection title="Restaurant & Bar Facilites" />}>
			<FacilityDetailSection />
		</Layout>
	);
};

export default FacilityDetails;
