import React from "react";
import Heading from "../components/Heading";
import TeamCard from "../components/TeamCard";
import tmImg1 from "../../assets/images/team/tm-1.jpg";
import tmImg2 from "../../assets/images/team/tm-2.jpg";
import tmImg3 from "../../assets/images/team/tm-3.jpg";
import tmImg4 from "../../assets/images/team/tm-4.jpg";
import tmImg5 from "../../assets/images/team/image-1.png";
import tmImg6 from "../../assets/images/team/image-2.png";
import tmImg7 from "../../assets/images/team/image-3.png";
import tmImg8 from "../../assets/images/team/image-4.png";
import tmImg9 from "../../assets/images/team/image-5.png";
import tmImg10 from "../../assets/images/team/image-6.png";
import tmImg11 from "../../assets/images/team/image-7.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const TeamSection = ({ isListingPage }) => {
	return (
		<div className="py-[100px] bg-cover bg-[#F5FAFF] bg-no-repeat bg-center w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col">
				{!isListingPage && (
					<Heading
						headline="Our Facilites"
						title={
							<>
								Let’s Meet Our
								<br /> Professional Team
							</>
						}
					/>
				)}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-10">
					<TeamCard
						teamImg={tmImg1}
						heading="Daisy Stracke"
						designation="Project Manager"
					/>
					<TeamCard
						teamImg={tmImg2}
						heading="Hortense Lazuardi"
						designation="Digital Markering Expert"
					/>
					<TeamCard
						teamImg={tmImg3}
						heading="Kimberly Greene"
						designation="SEO Team Leader"
					/>
					<TeamCard
						teamImg={tmImg4}
						heading="Henri Novitasari"
						designation="SEO Director"
					/>
					{isListingPage && (
						<>
							<TeamCard
								teamImg={tmImg5}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg6}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg7}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg8}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg9}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg10}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg11}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
							<TeamCard
								teamImg={tmImg2}
								heading="Henri Novitasari"
								designation="SEO Director"
							/>
						</>
					)}
				</div>
				{!isListingPage && (
					<Link to={ROUTES.team}>
						<button className="btn-primary mt-8">View All Team Member</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default TeamSection;
