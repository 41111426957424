import React from "react";
import roomImg from "../../assets/images/room-detail.png";
import ListItem from "../components/ListItem";
import Heading from "../components/Heading";
import grImg1 from "../../assets/images/grid/grid-1.png";
import grImg2 from "../../assets/images/grid/grid-2.png";
import grImg3 from "../../assets/images/grid/grid-3.png";
import grImg4 from "../../assets/images/grid/grid-4.png";
import grImg5 from "../../assets/images/grid/grid-5.png";
import grImg6 from "../../assets/images/grid/grid-6.png";
import TestimonialCard from "../components/TestimonialCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const RoomDetailSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 flex-col items-start">
				<div className="bg-troo-secondary text-troo-light font-bold text-2xl p-4 rounded-md">
					$65/ Per Night
				</div>
				<div className="grid lg:grid-cols-4 grid-cols-2 py-7 border-y border-troo-secondary border-opacity-20 w-full">
					<div className="w-full flex items-center justify-center text-center flex-col gap-2 py-5">
						<h6>Room Size</h6>
						<p>900 SQ.FT</p>
					</div>
					<div className="w-full flex items-center justify-center text-center flex-col gap-2 py-5 border-l lg:border-x border-troo-secondary border-opacity-20">
						<h6>Beds</h6>
						<p>2 Big Size Beds</p>
					</div>
					<div className="w-full flex items-center justify-center text-center flex-col gap-2 py-5 border-r border-troo-secondary border-opacity-20">
						<h6>Capacity</h6>
						<p>Upto 3 Guest</p>
					</div>
					<div className="w-full flex items-center justify-center text-center flex-col gap-2 py-5">
						<h6>View</h6>
						<p>Ocean View</p>
					</div>
				</div>
				<div className="flex flex-col items-start gap-3">
					<h3>OVERVIEW</h3>
					<h5>Family Room TRoo Hotel and Resort</h5>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
						consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris.
					</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
						consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua.
					</p>
					<img src={roomImg} alt="room" className="w-full aspect-video" />
				</div>
				<div className="flex flex-col items-start gap-3 pb-6 border-b border-troo-secondary border-opacity-20">
					<h3>FEATURED AMENITIES</h3>
					<h5>Room Amenities</h5>
					<div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-y-4 gap-6 2xl:gap-x-20">
						<ListItem itemText="Breakfast" />
						<ListItem itemText="42 Inch flat screen TV" />
						<ListItem itemText="Hair dryer" />
						<ListItem itemText="Coffee & tea making facilities" />
						<ListItem itemText="In-room safe" />
						<ListItem itemText="Mini-refrigerator" />
						<ListItem itemText="Complimentary bottled water" />
						<ListItem itemText="Telephone" />
						<ListItem itemText="Free Wi-Fi" />
						<ListItem itemText="24-Hour Room Service" />
						<ListItem itemText="Air Conditioning" />
						<ListItem itemText="Laundry Service" />
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 pb-6 border-b border-troo-secondary border-opacity-20">
					<h3>ROOM RULES</h3>
					<h5>Rules & Regulation</h5>
					<div className="w-full flex items-center gap-x-16 gap-y-4 flex-wrap">
						<ListItem itemText="Check-in: After 02:00pm" />
						<ListItem itemText="Checkout: Before 11:00am" />
						<ListItem itemText="Late Checkout: Additional charge 50% of the room rate." />
						<ListItem itemText="No pets" />
						<ListItem itemText="Identification document is must for hotel registration." />
						<ListItem itemText="No smoking in side the room" />
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 pb-6 border-b border-troo-secondary border-opacity-20">
					<h3>SECURITY</h3>
					<h5>Safety and Security</h5>
					<div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-y-4 gap-6 2xl:gap-x-20">
						<ListItem itemText="Cupboards With Locks" />
						<ListItem itemText="Electronic Safe" />
						<ListItem itemText="Safety" />
						<ListItem itemText="Smoke Alarm" />
						<ListItem itemText="24/7 Fire Services" />
						<ListItem itemText="24/7 Cc Tv Camera" />
						<ListItem itemText="24/7 Gaurd Service" />
					</div>
				</div>
				<div className="flex flex-col items-start w-full gap-6">
					<Heading
						alignStart
						headline="Photo Gallery"
						title={
							<>
								Let’s see Family Room
								<br /> Photo and Video
							</>
						}
					/>
					<div className="w-full grid grid-cols-2 md:grid-cols-4 gap-8">
						<div className="col-span-2 w-full h-full">
							<img
								src={grImg1}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg2}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg3}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg4}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg5}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="col-span-2 w-full h-full">
							<img
								src={grImg6}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
					</div>
				</div>
				<p className="font-jost self-center">
					Get <span className="text-troo-primary font-bold">30% Discount</span>{" "}
					on First Time Booking
				</p>
				<button className="btn-primary self-center">Book This Room</button>
				<div className="w-full flex flex-col items-center pt-[80px]">
					<Heading
						headline="Our Testimonials"
						title={
							<>
								Our Customers
								<br /> Love What We Do
							</>
						}
					/>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
						<TestimonialCard />
						<TestimonialCard />
						<TestimonialCard />
						<TestimonialCard />
					</div>
					<Link to={ROUTES.testimonial}>
						<button className="btn-primary self-center mt-16">
							View All Testimonials
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default RoomDetailSection;
