import React from "react";
import Heading from "../components/Heading";
import BlogCard from "../components/BlogCard";
import img1 from "../../assets/images/blog/blog1.jpg";
import img2 from "../../assets/images/blog/blog2.jpg";
import img3 from "../../assets/images/blog/blog3.jpg";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogSection = ({ isListingPage }) => {
	return (
		<div className="py-[100px] bg-[#F5FAFF] bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 items-center flex-col">
				{!isListingPage && (
					<Heading
						headline="News and Updates"
						title={
							<>
								Featured tips and tricks
								<br /> Exclusively for you!
							</>
						}
					/>
				)}
				<div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
					<BlogCard blogImg={img1} />
					<BlogCard blogImg={img2} />
					<BlogCard blogImg={img3} />
					{isListingPage && (
						<>
							<BlogCard blogImg={img1} />
							<BlogCard blogImg={img2} />
							<BlogCard blogImg={img3} />
							<BlogCard blogImg={img1} />
							<BlogCard blogImg={img2} />
							<BlogCard blogImg={img3} />
						</>
					)}
				</div>
				{!isListingPage && (
					<Link to={ROUTES.blogsGrid}>
						<button className="btn-primary">View All Blogs</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default BlogSection;
