import React from "react";
import Heading from "../components/Heading";
import TestimonialCard from "../components/TestimonialCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const TestimonialSection = () => {
	return (
		<div className="py-[100px] bg-cover min-h-[800px] bg-troo-light bg-no-repeat bg-center w-full">
			<div className="main-container-big gap-8 items-start grid grid-cols-1 lg:grid-cols-2">
				<div className="w-full h-full flex flex-col justify-center">
					<div className="w-full flex flex-col items-start gap-5 ">
						<Heading
							alignStart
							headline="Our Testimonials"
							title={
								<>
									Our Customers
									<br /> Love What We Do
								</>
							}
						/>
						<p className="text-gray-500">
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised.
						</p>
						<Link to={ROUTES.testimonial}>
							<button className="btn-primary">View All Testimonials</button>
						</Link>
					</div>
				</div>
				<div className="w-full relative">
					<div className="lg:absolute top-0 z-10 w-full flex flex-col gap-8">
						<TestimonialCard />
						<TestimonialCard />
						<TestimonialCard />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TestimonialSection;
