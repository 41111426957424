import { ROUTES } from "./routes";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		name: "Facilites",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.facilities,
				name: "Facilities List",
			},
			{
				path: ROUTES.facilityDetails,
				name: "Facility Detail",
			},
		],
	},
	{
		name: "Rooms & Suits",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.roomSuits,
				name: "Rooms & Suits List",
			},
			{
				path: ROUTES.roomSuitsDetails,
				name: "Room & Suits Detail",
			},
		],
	},
	{
		name: "Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.team,
				name: "Team",
			},
			{
				path: ROUTES.teamDetails,
				name: "Team Detail",
			},
			{
				path: ROUTES.testimonial,
				name: "Testimonial",
			},
			{
				path: ROUTES.faq,
				name: "FAQ",
			},
			{
				path: ROUTES.pricing,
				name: "Pricing",
			},
			{
				path: ROUTES.errorPage,
				name: "404",
			},
			{
				path: ROUTES.comingSoon,
				name: "Coming Soon",
			},
		],
	},
	{
		name: "Blogs",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.blogsGrid,
				name: "Blogs Grid",
			},
			{
				path: ROUTES.blogDetails,
				name: "Blog Details",
			},
		],
	},
	{
		path: ROUTES.contactPage,
		name: "Contact us",
	},
];
