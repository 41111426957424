import React from "react";
import PricingCard from "../components/PricingCard";

const PricingSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="relative main-container-big h-full gap-8 items-center grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
				<PricingCard />
				<PricingCard />
				<PricingCard />
				<PricingCard />
				<PricingCard />
				<PricingCard />
			</div>
		</div>
	);
};

export default PricingSection;
