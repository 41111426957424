import React from "react";
import Layout from "../shared/components/Layout";
import HeroSection from "../shared/sections/HeroSection";
import AdvantageSection from "../shared/sections/AdvantageSection";
import RoomSection from "../shared/sections/RoomSection";
import AboutSection from "../shared/sections/AboutSection";
import VideoBanner from "../shared/sections/VideoBanner";
import FacilitySection from "../shared/sections/FacilitySection";
import TestimonialSection from "../shared/sections/TestimonialSection";
import FaqSection from "../shared/sections/FaqSection";
import WorkProgressSection from "../shared/sections/WorkProgressSection";
import BlogSection from "../shared/sections/BlogSection";
import useDocumentTitle from "../Hooks/PageTitle";

const Home = () => {
	useDocumentTitle("TRoo Hotel & Resort - React Js Template")
	return (
		<Layout banner={<HeroSection />}>
			<AdvantageSection />
			<RoomSection />
			<AboutSection />
			<VideoBanner />
			<FacilitySection />
			<TestimonialSection />
			<FaqSection />
			<WorkProgressSection />
			<BlogSection />
		</Layout>
	);
};

export default Home;
