import React from "react";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOpenOutline } from "react-icons/io5";

const Header = () => {
	return (
		<div
			className={`drawer z-10 hidden lg:flex bg-troo-tertiary md:relative w-full flex-col items-start`}
		>
			<input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content main-container w-full flex items-center justify-between xl:grid grid-cols-3 py-2 text-troo-light">
				<div className="flex items-center gap-4">
					<div className="flex items-center gap-1">
						<FiPhoneCall className="text-2xl" />
						<p className="p-2">Hotline: +44 123 456 7890</p>
					</div>
					<span className="divider-dot" />
					<div className="flex items-center gap-1">
						<IoMailOpenOutline className="text-2xl" />
						<p className="p-2">Email: info@troothemes.com</p>
					</div>
				</div>
				<div className="hidden xl:flex justify-center">
					<p className="text-troo-light text-sm">
						Welcome Message “Write something here
					</p>
				</div>
				<div className="flex items-center gap-3 justify-end">
					<div className="bg-troo-primary text-troo-light p-2 rounded-full">
						<FaFacebookF className="text-xl" />
					</div>
					<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
						<FaInstagram className="text-xl" />
					</div>
					<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
						<FaXTwitter className="text-xl" />
					</div>
					<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
						<FaLinkedinIn className="text-xl" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
