import React from "react";

const ProcessCard = ({ heading, count }) => {
	return (
		<div className="w-full flex flex-col items-center text-troo-secondary gap-5">
			<div className="font-outline-2 bg-troo-secondary p-3 rounded-full outline-1 outline-dashed outline-troo-secondary outline-offset-4 aspect-square text-center flex items-center justify-center text-transparent text-3xl font-bold font-jost">
				{count}
			</div>
			<h5 className="text-center">{heading}</h5>
			<p className="text-sm text-center text-opacity-20">
				Lorem Ipsum is simply dummy text of the printing and typesetting
				industry.
			</p>
		</div>
	);
};

export default ProcessCard;
