import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import TeamSection from "../shared/sections/TeamSection";
import AdvantageSection from "../shared/sections/AdvantageSection";
import useDocumentTitle from "../Hooks/PageTitle";

const OurTeam = () => {
	useDocumentTitle("TRoo Hotel & Resort | Our Teams")
	return (
		<Layout banner={<SubHeroSection title="Our Awesome Team" />}>
			<TeamSection isListingPage />
			<AdvantageSection />
		</Layout>
	);
};

export default OurTeam;
