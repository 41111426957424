import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { ROUTES } from "./shared/constants/routes";
import AboutUs from "./pages/AboutUs";
import OurTeam from "./pages/OurTeam";
import TeamDetails from "./pages/TeamDetails";
import Faqs from "./pages/Faqs";
import Testimonial from "./pages/Testimonial";
import SuitsAndRooms from "./pages/SuitsAndRooms";
import Facilities from "./pages/Facilities";
import OurNews from "./pages/OurNews";
import ErrorPage from "./pages/ErrorPage";
import ComingSoon from "./pages/ComingSoon";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import RoomDeails from "./pages/RoomDeails";
import FacilityDetails from "./pages/FacilityDetails";
import BlogDetails from "./pages/BlogDetails";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={ROUTES.home} element={<Home />} />
					<Route path={ROUTES.aboutUs} element={<AboutUs />} />
					<Route path={ROUTES.team} element={<OurTeam />} />
					<Route path={ROUTES.teamDetails} element={<TeamDetails />} />
					<Route path={ROUTES.faq} element={<Faqs />} />
					<Route path={ROUTES.testimonial} element={<Testimonial />} />
					<Route path={ROUTES.roomSuits} element={<SuitsAndRooms />} />
					<Route path={ROUTES.facilities} element={<Facilities />} />
					<Route path={ROUTES.blogsGrid} element={<OurNews />} />
					<Route path={ROUTES.errorPage} element={<ErrorPage />} />
					<Route path={ROUTES.comingSoon} element={<ComingSoon />} />
					<Route path={ROUTES.pricing} element={<Pricing />} />
					<Route path={ROUTES.contactPage} element={<Contact />} />
					<Route path={ROUTES.roomSuitsDetails} element={<RoomDeails />} />
					<Route path={ROUTES.facilityDetails} element={<FacilityDetails />} />
					<Route path={ROUTES.blogDetails} element={<BlogDetails />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
