import React from "react";
import roomImg from "../../assets/images/room-detail.png";
import ListItem from "../components/ListItem";
import Heading from "../components/Heading";
import grImg1 from "../../assets/images/grid/grid-1.png";
import grImg2 from "../../assets/images/grid/grid-2.png";
import grImg3 from "../../assets/images/grid/grid-3.png";
import grImg4 from "../../assets/images/grid/grid-4.png";
import grImg5 from "../../assets/images/grid/grid-5.png";
import grImg6 from "../../assets/images/grid/grid-6.png";
import TestimonialCard from "../components/TestimonialCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const FacilityDetailSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 flex-col items-start">
				<div className="flex flex-col items-start gap-3">
					<p>Eat and Drink</p>
					<h3>Restaurant & Bar</h3>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined chunks as necessary, making this the first true
						generator on the Internet. It uses a dictionary of over 200 Latin
						words, combined with a handful of model sentence structures, to
						generate Lorem Ipsum which looks reasonable. The generated Lorem
						Ipsum is therefore always free from repetition, injected humour, or
						non-characteristic words etc.
					</p>
					<img src={roomImg} alt="room" className="w-full aspect-video" />
				</div>

				<div className="flex flex-col items-start gap-3 pb-6 border-b border-troo-secondary border-opacity-20">
					<h3>CONTACT DETAIL</h3>
					<h5>Contact us</h5>
					<div className="w-full flex items-center gap-x-16 gap-y-4 flex-wrap">
						<ListItem itemText="Call Us: +44 1 58 36 60 50" />
						<ListItem itemText="restaurant@troothemes.com" />
						<ListItem itemText="22 North Ewingar Road Lionsville, New South Wales, 2460 Australia" />
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 pb-6 border-b border-troo-secondary border-opacity-20">
					<h3>OPENING HOURS</h3>
					<h5>from Monday to Friday</h5>
					<div className="w-full flex items-center gap-x-16 gap-y-4 flex-wrap">
						<ListItem itemText="Lunch: 12:30pm to 1:30pm" />
						<ListItem itemText="Dinner: 7:30pm to 9:30pm" />
						<ListItem itemText="Saturday and Sunday: Closed" />
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 pb-6 border-b border-troo-secondary border-opacity-20">
					<h3>MENU</h3>
					<h5>See Our Menu</h5>
					<div className="w-full flex items-center gap-x-4 lg:gap-x-16 gap-y-4 flex-wrap">
						<button className="btn-primary">Lunch and Dinner Menu</button>
						<button className="btn-secondary">Breakfast Menu</button>
						<button className="btn-secondary">Drink Menu</button>
						<button className="btn-secondary">Dessert Menu</button>
					</div>
				</div>

				<div className="flex flex-col items-start w-full gap-6">
					<Heading
						alignStart
						headline="Photo Gallery"
						title={
							<>
								Let’s see Restaurant - Bar
								<br /> Photo and Video
							</>
						}
					/>
					<div className="w-full grid grid-cols-2 md:grid-cols-4 gap-8">
						<div className="col-span-2 w-full h-full">
							<img
								src={grImg1}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg2}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg3}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg4}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg5}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="col-span-2 w-full h-full">
							<img
								src={grImg6}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
					</div>
				</div>
				<p className="font-jost self-center">
					Get <span className="text-troo-primary font-bold">30% Discount</span>{" "}
					on First Time Booking
				</p>
				<button className="btn-primary self-center">Book This Room</button>
				<div className="w-full flex flex-col items-center pt-[80px]">
					<Heading
						headline="Our Testimonials"
						title={
							<>
								Our Customers
								<br /> Love What We Do
							</>
						}
					/>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
						<TestimonialCard />
						<TestimonialCard />
						<TestimonialCard />
						<TestimonialCard />
					</div>
					<Link to={ROUTES.testimonial}>
						<button className="btn-primary self-center mt-16">
							View All Testimonials
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default FacilityDetailSection;
