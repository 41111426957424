export const ROUTES = {
	home: "/",
	aboutUs: "/about-us",
	services: "/services",
	roomSuits: "/room-and-suits",
	roomSuitsDetails: "/room-and-suits-details",
	facilities: "/facilities",
	facilityDetails: "/facility-details",
	serviceDetails: "/services/service-details",
	portfolio: "/portfolio",
	portfolioDetails: "/portfolio/portfolio-details",
	team: "/team",
	teamDetails: "/team/team-details",
	blogsGrid: "/blogs",
	blogsList: "/blogs-list",
	blogDetails: "/blogs/blog-details",
	faq: "/faq",
	pricing: "/pricing",
	testimonial: "/testimonial",
	contactPage: "/contact-us",
	makeAnAppointment: "/make-an-appointment",
	errorPage: "/404",
	comingSoon: "/coming-soon",
};
