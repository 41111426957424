import React from "react";
import Heading from "../components/Heading";
import AdvantageCard from "../components/AdvantageCard";
import customeSupport from "../../assets/images/customer-support.png";
import Discount from "../../assets/images/discount.png";
import FreeWifi from "../../assets/images/free-wifi.png";
import Conference from "../../assets/images/conference.png";

const AdvantageSection = () => {
	return (
		<div className="py-[100px] w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col">
				<Heading
					headline="Our Advantages"
					title={
						<>
							Enjoy unforgettable
							<br /> experiences in our hotels
						</>
					}
				/>
				<p className="text-center">
					Contrary to popular belief, Lorem Ipsum is not simply random text. It
					has roots in a piece of classical Latin literature from 45 BC,
					<br /> making it over 2000 years old.
				</p>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
					<AdvantageCard
						heading="Customer Support"
						description="24X7 Customers Support"
						icon={customeSupport}
					/>
					<AdvantageCard
						heading="A Lot of Discount"
						description="Completely maintain parallel"
						icon={Discount}
					/>
					<AdvantageCard
						heading="Free WIFI Zone"
						description="Completely maintain parallel"
						icon={FreeWifi}
					/>
					<AdvantageCard
						heading="Conference Room"
						description="Completely maintain parallel"
						icon={Conference}
					/>
				</div>
			</div>
		</div>
	);
};

export default AdvantageSection;
