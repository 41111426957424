import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import TestimonialListSection from "../shared/sections/TestimonialListSection";
import useDocumentTitle from "../Hooks/PageTitle";

const Testimonial = () => {
	useDocumentTitle("TRoo Hotel & Resort | Testimonials")
	return (
		<Layout banner={<SubHeroSection title="Our Clients Feedback" />}>
			<TestimonialListSection />
		</Layout>
	);
};

export default Testimonial;
