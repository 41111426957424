import React from "react";
import Header from "./Header";
import HeaderMenu from "./HeaderMenu";
// import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import HeroSectionForm from "./HeroSectionForm";
import Footer from "./Footer";
import CopyrightFooter from "./CopyrightFooter";
const Layout = ({ banner, children, errorPage }) => {
	const location = useLocation();
	return (
		<>
			<Header />
			<div
				className={`${
					location.pathname === ROUTES.home
						? "bg-home-hero-banner"
						: "bg-subpage"
				} bg-no-repeat bg-cover bg-center`}
			>
				<div className="bg-troo-secondary bg-opacity-50">
					<HeaderMenu />
					<div className="">{banner}</div>
				</div>
			</div>
			{location.pathname === ROUTES.home && (
				<div className="main-container-big -mt-40">
					<HeroSectionForm />
				</div>
			)}
			<main>{children}</main>
			{!errorPage && (
				<>
					<Footer />
					<CopyrightFooter />
				</>
			)}
		</>
	);
};

export default Layout;
