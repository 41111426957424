import React from "react";
import Input from "./Input";
import { BsCalendar3Event, BsPerson } from "react-icons/bs";

const HeroSectionForm = () => {
	return (
		<div className="p-[28px] md:p-[50px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 bg-troo-light w-full rounded-lg shadow-lg">
			<Input
				icon={<BsCalendar3Event />}
				placeholder="01 May, 2023"
				label="Check In"
			/>
			<Input icon={<BsPerson />} placeholder="00" label="Adult" type="number" />
			<div className="flex flex-col items-start gap-3">
				<p className="font-jost">Room Type</p>
				<select className="flex appearance-none items-center w-full p-3 justify-between border-2 border-troo-primary rounded-lg border-opacity-50">
					<option>Room Type</option>
					<option>Luxurious Room</option>
					<option>General Room</option>
					<option>Deluxe Room</option>
				</select>
			</div>
			<Input
				icon={<BsCalendar3Event />}
				placeholder="03 May, 2023"
				label="Check Out"
			/>
			<Input
				icon={<BsPerson />}
				placeholder="00"
				type="number"
				label="Children"
			/>
			<div className="flex flex-col items-start gap-3 justify-end w-full">
				<button className="btn-secondary w-full">Book Your Stay</button>
			</div>
		</div>
	);
};

export default HeroSectionForm;
