import React from "react";
import soonImg from "../assets/images/soon.png";
import Heading from "../shared/components/Heading";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import useDocumentTitle from "../Hooks/PageTitle";

const ComingSoon = () => {
	useDocumentTitle("TRoo Hotel & Resort | ComingSoon")
	return (
		<div className="w-full min-h-screen grid lg:grid-cols-2">
			<div className="w-full h-full p-6 lg:p-20 flex flex-col items-start justify-center gap-4 bg-troo-secondary">
				<Heading
					alignStart
					light
					title="We Will Launching Our 
Website Very Soon"
					headline="Working Process"
				/>
				<p className="text-troo-light">
					We are working hard to give you a better experience with our new
					impressive site. Stay connected, Stay updated!
				</p>
				<div className="flex flex-col items-start gap-3">
					<p className="text-troo-light font-bold font-jost">Notify Me </p>
					<div className="w-full grid grid-cols-1 md:flex flex-col md:flex-row items-center">
						<input
							placeholder="Enter your email"
							className="p-4 bg-transparent border border-troo-light border-opacity-20 rounded-md"
						/>
						<button className="btn-primary md:w-auto w-full">Submit</button>
					</div>
					<p className="text-xl text-troo-light mt-5">
						For more inquiry:{" "}
						<span className="font-bold text-troo-primary">
							contact@troothems.com
						</span>
					</p>
					<p className="text-xl text-troo-light">
						Call Us any Time:{" "}
						<span className="font-bold text-troo-primary">
							+44 132 456 7890
						</span>
					</p>
					<div className="flex items-center gap-3 mt-8">
						<p className="text-troo-light text-xl">Social Media: </p>
						<div className="bg-troo-primary text-troo-light p-2 rounded-full">
							<FaFacebookF className="text-lg" />
						</div>
						<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
							<FaInstagram className="text-lg" />
						</div>
						<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
							<FaXTwitter className="text-lg" />
						</div>
						<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
							<FaLinkedinIn className="text-lg" />
						</div>
					</div>
				</div>
			</div>
			<div className="w-full h-full lg:block hidden">
				<img
					src={soonImg}
					alt="soon img"
					className="w-full h-full object-cover"
				/>
			</div>
		</div>
	);
};

export default ComingSoon;
