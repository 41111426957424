import React from "react";
import { BsTelephoneOutbound } from "react-icons/bs";
import { HiOutlineLocationMarker, HiOutlineMailOpen } from "react-icons/hi";

const ContactSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 items-center flex-col">
				<div className="w-full h-auto">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.001524964495!2d72.7848282758056!3d21.19209838049845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d198e284e31%3A0x56c3bab7f1aff804!2sTRooTech%20Business%20Solutions%20Pvt.%20Ltd.%20-%20Surat!5e0!3m2!1sen!2sin!4v1696402548905!5m2!1sen!2sin"
						width="100%"
						title="trootech"
						height="450"
						style={{ border: 0 }}
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
				<div className="grid grid-cols-1 xl:grid-cols-3 items-end justify-between gap-8 w-full">
					<div className="xl:col-span-2 w-full p-12 rounded-md flex flex-col items-start gap-4 shadow-xl">
						<h3>Have Any Question?</h3>
						<p>
							It is a long established fact that a reader will be distracted
							content of a page when looking.
						</p>
						<div className="w-full grid grid-cols-2 gap-3">
							<input
								placeholder="First Name"
								className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
							/>
							<input
								placeholder="Last Name"
								className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
							/>
							<input
								placeholder="Phone No"
								className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
							/>
							<input
								placeholder="Email Adress"
								className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
							/>
							<select className="p-4 col-span-2 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none appearance-none">
								<option>Select Subject</option>
								<option>Subject 1</option>
								<option>Subject 2</option>
								<option>Subject 3</option>
							</select>
							<textarea
								placeholder="Message"
								className="p-4 col-span-2 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none appearance-none"
								rows={6}
							></textarea>
						</div>
						<button className="btn-primary">Get In Touch</button>
					</div>
					<div className="w-full flex flex-col items-start gap-4">
						<div className="flex items-start gap-3 bg-troo-light rounded-lg shadow-xl p-8 w-full">
							<div className="p-4 rounded-full bg-troo-primary bg-opacity-10 text-troo-primary">
								<BsTelephoneOutbound className="text-xl" />
							</div>
							<div className="flex flex-col items-start gap-1 text-troo-secondary">
								<p className="font-medium">Call Us on:</p>
								<h5>+44 123 456 7890</h5>
								<h5>+44 987 654 3210</h5>
							</div>
						</div>
						<div className="flex items-start gap-3 bg-troo-light rounded-lg shadow-xl p-8 w-full">
							<div className="p-4 rounded-full bg-troo-primary bg-opacity-10 text-troo-primary">
								<HiOutlineMailOpen className="text-xl" />
							</div>
							<div className="flex flex-col items-start gap-1 text-troo-secondary">
								<p className="font-medium">Email Us</p>
								<h5>info@troothemes.com</h5>
								<h5>contact@troothemes.com</h5>
							</div>
						</div>

						<div className="flex items-start gap-3 bg-troo-light rounded-lg shadow-xl p-8 w-full">
							<div className="p-4 rounded-full bg-troo-primary bg-opacity-10 text-troo-primary">
								<HiOutlineLocationMarker className="text-xl" />
							</div>
							<div className="flex flex-col items-start gap-1 text-troo-secondary">
								<p className="font-medium">Our Location</p>
								<h5>Ewingar Road Lionsville,</h5>
								<h5>New South Wales, 2460 Aus</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;
