import React from "react";
import { BsFillStarFill, BsPlayFill } from "react-icons/bs";
import { motion } from "framer-motion";

const VideoBanner = () => {
	return (
		<div className="bg-video-banner-bottom bg-cover bg-top bg-no-repeat w-full">
			<div className="py-[100px] bg-troo-secondary bg-video-banner-upper bg-cover bg-no-repeat w-full h-full bg-opacity-80">
				<div className="main-container-big grid grid-cols-1 md:grid-cols-2 h-full gap-8 items-center">
					<div className="flex flex-col items-start gap-6">
						<div className="flex items-center gap-1 p-3 bg-troo-primary text-troo-light rounded-lg">
							<BsFillStarFill />
							<BsFillStarFill />
							<BsFillStarFill />
							<BsFillStarFill />
							<BsFillStarFill />
						</div>
						<h2 className="text-troo-light font-bold">
							Get the better rate & discount
							<br /> 25% only for this month.
						</h2>
						<p className="text-troo-light">
							Lorem ipsum dolor sit amet, and consectetur adipiscing elit. Ut
							elit tellus, luctus nec ullamcorper also it isconsectetur
							adipiscing elit. Ut elit tellus, consectetur adipiscing elit. Ut
							elit tellus.
						</p>
						<button className="btn-primary">Book Now!</button>
					</div>
					<div
						className=" w-full h-full bg-troo-primaryDark bg-opacity-50 flex items-center justify-center"
						onClick={() => window.my_modal_2.showModal()}
					>
						<motion.div
							initial={{ scale: 0.8 }}
							whileInView={{ scale: 1 }}
							transition={{
								ease: "linear",
								repeat: Infinity,
								duration: 1,
							}}
							className="relative flex items-center justify-center bg-troo-primary text-troo-primaryDark rounded-full p-5 text-5xl z-[1]"
						>
							<div className="absolute w-full h-full bg-troo-light rounded-full bg-opacity-40 scale-125 -z-[1] animate-pulse animate-infinite duration-100 delay-75"></div>
							<div className="absolute w-full h-full bg-troo-light rounded-full bg-opacity-30 scale-150 -z-[1] animate-pulse animate-infinite duration-300 delay-200"></div>
							<div className="absolute w-full h-full bg-troo-light rounded-full bg-opacity-20 scale-[1.75] -z-[1] animate-pulse animate-infinite duration-700 delay-500"></div>
							<BsPlayFill className="text-troo-light" />
						</motion.div>
					</div>
				</div>
				<dialog id="my_modal_2" className="modal">
					<form
						method="dialog"
						className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
					>
						<iframe
							width="100%"
							height="500"
							src="https://www.youtube.com/embed/DUfXdcpEfMs"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</form>
					<form method="dialog" className="modal-backdrop">
						<button>close</button>
					</form>
				</dialog>
			</div>
		</div>
	);
};

export default VideoBanner;
