import React from "react";
import Heading from "../components/Heading";
import ProcessCard from "../components/ProcessCard";
import ArrowShape from "../../assets/images/arrow-shape.png";

const WorkProgressSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-troo-light bg-no-repeat bg-center w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col">
				<Heading headline="Working Process" title={<>How does we works</>} />
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:flex items-start gap-6 lg:gap-2">
					<ProcessCard heading="Visit Our Website" count="01" />
					<img
						src={ArrowShape}
						alt="arrow"
						className="hidden lg:block self-center"
					/>
					<ProcessCard heading="Consult With Us" count="02" />
					<img
						src={ArrowShape}
						alt="arrow"
						className="hidden lg:block self-center"
					/>
					<ProcessCard heading="Check In" count="03" />
					<img
						src={ArrowShape}
						alt="arrow"
						className="hidden lg:block self-center"
					/>
					<ProcessCard heading="Enjoy Your Time" count="04" />
				</div>
			</div>
		</div>
	);
};

export default WorkProgressSection;
