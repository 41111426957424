import React from "react";

const Input = ({ placeholder, type, icon, label, ...rest }) => {
	return (
		<div className="flex flex-col items-start gap-3">
			{label && <p className="font-jost">{label}</p>}
			<div className="flex items-center w-full p-3 justify-between border-2 border-troo-primary rounded-lg border-opacity-50">
				<input
					type={type}
					placeholder={placeholder}
					{...rest}
					className="bg-transparent outline-none w-full"
				/>
				<div className="text-troo-tertiary text-xl">{icon}</div>
			</div>
		</div>
	);
};

export default Input;
