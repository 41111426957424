import React from "react";
import roomImg from "../../assets/images/room-detail.png";
import ListItem from "../components/ListItem";
import grImg4 from "../../assets/images/grid/grid-4.png";
import grImg5 from "../../assets/images/grid/grid-5.png";
import grImg6 from "../../assets/images/grid/grid-6.png";
import img1 from "../../assets/images/blog/blog1.jpg";
import img2 from "../../assets/images/blog/blog2.jpg";
import img3 from "../../assets/images/blog/blog3.jpg";
import {
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaQuoteLeft,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import BlogCard from "../components/BlogCard";

const BlogDetailSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 flex-col items-start text-sm md:text-base">
				<div className="flex flex-col items-start gap-3 w-full">
					<img src={roomImg} alt="room" className="w-full aspect-video" />
					<p>Posted by: Adam Cole | Date: 20 Jan, 2023</p>
					<h3>Hyatt Pledges to Open 5,000 Rooms in Mexico’s Tulum</h3>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined chunks as necessary, making this the first true
						generator on the Internet. It uses a dictionary of over 200 Latin
						words, combined with a handful of model sentence structures, to
						generate Lorem Ipsum which looks reasonable. The generated Lorem
						Ipsum is therefore always free from repetition, injected humour, or
						non-characteristic words etc.
					</p>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined chunks as necessary, making this the first true
						generator on the Internet.
					</p>
				</div>

				<div className="flex flex-col items-start gap-3">
					<h3>Develop a brand identity</h3>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable..
					</p>
					<div className="w-full grid-cols-1 grid md:grid-cols-2 gap-3">
						<ListItem itemText="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
						<ListItem itemText="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
						<ListItem itemText="Lorem ipsum dolor sit amet." />
						<ListItem itemText="Lorem ipsum dolor sit amet." />
						<ListItem itemText="Lorem ipsum dolor sit amet, consectetur" />
						<ListItem itemText="Lorem ipsum dolor sit amet, consectetur" />
						<ListItem itemText="There Is Lorem ipsum dolor sit amet, Lorem ipsum." />
						<ListItem itemText="There Is Lorem ipsum dolor sit amet, Lorem ipsum." />
					</div>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum.
					</p>
				</div>

				<div className="flex flex-col gap-2 bg-[#F5FAFF] p-9 border-l-4 border-troo-primary">
					<FaQuoteLeft className="text-2xl text-troo-primary" />
					<p>
						Lorem Ipsum is simply dummy text of the printin and typesetting
						industry. Lorem Ipsum has been the industry’s standard dummy text
						ever since the 1500s, when an unknown printer took a galley.
					</p>
				</div>

				<div className="flex flex-col items-start w-full gap-6">
					<div className="w-full grid grid-cols-2 md:grid-cols-4 gap-8">
						<div className="w-full h-full">
							<img
								src={grImg4}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full">
							<img
								src={grImg5}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="col-span-2 w-full h-full">
							<img
								src={grImg6}
								alt="img1"
								className="w-full h-full object-cover"
							/>
						</div>
					</div>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined chunks as necessary, making this the first true
						generator on the Internet. It uses a dictionary of over 200 Latin
						words.
					</p>
				</div>

				<div className="flex w-full items-center gap-4 p-6 bg-troo-primary bg-opacity-5">
					<p>Share This Article</p>
					<div className="flex items-center gap-3 justify-end">
						<div className="bg-troo-primary text-troo-light p-2 rounded-full">
							<FaFacebookF className="text-base" />
						</div>
						<div className="hover:bg-troo-primary text-troo-tertiary p-2 rounded-full">
							<FaInstagram className="text-base" />
						</div>
						<div className="hover:bg-troo-primary text-troo-tertiary p-2 rounded-full">
							<FaXTwitter className="text-base" />
						</div>
						<div className="hover:bg-troo-primary text-troo-tertiary p-2 rounded-full">
							<FaLinkedinIn className="text-base" />
						</div>
					</div>
				</div>

				<div className="flex flex-col items-start gap-4 w-full">
					<h3>Comments (0)</h3>
					<h6>Leave A Comment</h6>
					<div className="w-full grid grid-cols-2 gap-3">
						<input
							placeholder="First Name"
							className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
						/>
						<input
							placeholder="Last Name"
							className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
						/>
						<input
							placeholder="Phone No"
							className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
						/>
						<input
							placeholder="Email Adress"
							className="p-4 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none"
						/>
						<textarea
							placeholder="Message"
							className="p-4 col-span-2 rounded-md w-full bg-transparent border border-troo-secondary border-opacity-20 outline-none appearance-none"
							rows={6}
						></textarea>
					</div>
					<button className="btn-primary">Post Comment</button>
				</div>

				<div className="w-full pt-20 flex flex-col items-center gap-4">
					<h3>Related Blogs</h3>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
						<BlogCard blogImg={img1} />
						<BlogCard blogImg={img2} />
						<BlogCard blogImg={img3} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogDetailSection;
