import React from "react";
import { BsPatchCheck } from "react-icons/bs";

const ListItem = ({ itemText }) => {
	return (
		<div className="flex items-center gap-2">
			<div>
				<BsPatchCheck className="text-troo-primary" />
			</div>
			<span>{itemText}</span>
		</div>
	);
};

export default ListItem;
