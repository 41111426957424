import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import PricingSection from "../shared/sections/PricingSection";
import useDocumentTitle from "../Hooks/PageTitle";

const Pricing = () => {
	useDocumentTitle("TRoo Hotel & Resort | Pricing Card")
	return (
		<Layout banner={<SubHeroSection title="Choose Your Package" />}>
			<PricingSection />
		</Layout>
	);
};

export default Pricing;
