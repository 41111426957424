import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import BlogSection from "../shared/sections/BlogSection";
import useDocumentTitle from "../Hooks/PageTitle";

const OurNews = () => {
	useDocumentTitle("TRoo Hotel & Resort | Our News")
	return (
		<Layout banner={<SubHeroSection title="Our News & Blogs" />}>
			<BlogSection isListingPage />
		</Layout>
	);
};

export default OurNews;
