import React from "react";
import { motion } from "framer-motion";

const HeroSection = () => {
	return (
		<div className="py-[100px] h-[720px] w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col justify-start">
				<div className="flex items-center gap-3">
					<div className="flex items-center gap-1">
						<div className="w-[4px] h-[4px] rounded-full bg-troo-primary"></div>
						<div className="w-[35px] h-[4px] bg-troo-primary rounded-2xl"></div>
					</div>
					<p className="text-troo-light text-xl">
						Welcome to TRoo Hotel & Resort
					</p>
				</div>
				<motion.h1
					initial={{ scale: 0.8 }}
					whileInView={{ scale: 1 }}
					transition={{ type: "spring", stiffness: 100 }}
					className="text-troo-light font-bold text-center"
				>
					Book Your Stay & Enjoy <br /> Luxury Experience
				</motion.h1>
				<p className="text-troo-light text-center">
					There are many variations of passages of Lorem Ipsum available, but
					the majority have
					<br /> suffered alteration in some form by injected.
				</p>
				<div className="flex items-center gap-3">
					<button className="btn-primary">Book Your Stay</button>
					<button className="btn-secondary">#1 Best Services</button>
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
