import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogCard = ({ blogImg }) => {
	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className="w-full flex flex-col items-start group"
		>
			<div className="relative aspect-video lg:aspect-square overflow-hidden rounded-lg group transition-all duration-100 ease-linear">
				<Link
					to={ROUTES.blogDetails}
					className="absolute p-8 bg-troo-secondary top-0 left-0 w-full h-full flex-col hidden group-hover:flex items-start aspect-square bg-opacity-50 transition-all duration-100 ease-linear"
				>
					<div className="bg-troo-primary font-medium text-troo-light px-4 py-2 rounded-lg">
						21 Jan
					</div>
				</Link>
				<Link to={ROUTES.blogDetails}>
					<img
						src={blogImg}
						alt="blog"
						className="object-cover w-full h-full"
					/>
				</Link>
			</div>
			<div className="bg-troo-light p-8 flex flex-col items-start gap-4 w-5/6 -mt-24 z-10 rounded-lg border-t-4 border-troo-tertiary group-hover:border-troo-primary shadow-lg">
				<h5 className="text-troo-tertiary group-hover:text-troo-primary">
					Hyatt Pledges to Open 5,000 Rooms in Mexico’s Tulum
				</h5>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered.
				</p>
			</div>
		</motion.div>
	);
};

export default BlogCard;
