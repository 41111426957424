import React from "react";
import TestimonialCard from "../components/TestimonialCard";

const TestimonialListSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="relative main-container-big h-full gap-8 items-center grid grid-cols-1 md:grid-cols-2">
				<TestimonialCard />
				<TestimonialCard />
				<TestimonialCard />
				<TestimonialCard />
				<TestimonialCard />
				<TestimonialCard />
				<TestimonialCard />
				<TestimonialCard />
			</div>
		</div>
	);
};

export default TestimonialListSection;
