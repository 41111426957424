import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import FacilitySection from "../shared/sections/FacilitySection";
import useDocumentTitle from "../Hooks/PageTitle";

const Facilities = () => {
	useDocumentTitle("TRoo Hotel & Resort | Facilities")
	return (
		<Layout banner={<SubHeroSection title="Our Facilites" />}>
			<FacilitySection isListingPage />
		</Layout>
	);
};

export default Facilities;
