import React from "react";
import Heading from "../components/Heading";
import Accordian from "../components/Accordian";
import RoomImg from "../../assets/images/room/Image-2.png";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { motion } from "framer-motion";

const FaqSection = () => {
	return (
		<div className="bg-cover bg-home-hero-banner bg-no-repeat bg-center w-full">
			<div className="w-full h-full bg-troo-secondary pb-[100px] pt-[200px] bg-opacity-80">
				<div className="relative main-container-big flex lg:flex-row flex-col h-full gap-16">
					<div className="w-full flex flex-col items-start gap-4">
						<Heading
							alignStart
							light
							headline="Our FAQ’s"
							title={
								<>
									Usually Asked Question
									<br /> From Customers
								</>
							}
						/>
						<p className="text-troo-light">
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised.
						</p>
						<Accordian
							title="Where can I find room descriptions?"
							desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
						/>
						<Accordian
							title="What taxes and/or additional charges apply?"
							desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
						/>
						<Accordian
							title="Can I get my corporate rate when making a reservation online?"
							desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
						/>
						<Accordian
							title="Will my credit card be charged prior to my arrival at the hotel?"
							desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
						/>
						<Accordian
							title="Do I need to guarantee my reservation with a credit card?"
							desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since."
						/>
					</div>
					<motion.div
						initial={{ scale: 0.8 }}
						whileInView={{ scale: 1 }}
						transition={{ type: "spring", stiffness: 100 }}
						className="relative flex flex-col items-start w-full xl:w-auto xl:min-w-[525px] max-h-[680px] overflow-hidden rounded-xl xl:min-h-[680px]"
					>
						<img
							src={RoomImg}
							alt="room"
							className="w-full h-full object-cover"
						/>
						<div className="absolute p-2 sm:p-6 md:p-12 bg-troo-secondary bg-opacity-20 top-0 left-0 w-full flex flex-col justify-end h-full">
							<div className="w-full bg-troo-light rounded-lg p-8 text-troo-secondary flex flex-col gap-1 md:gap-3 items-center text-center">
								<h6>Can’t find answer?</h6>
								<p>Contact us on</p>
								<HiOutlineChatBubbleLeftRight className="text-xl md:text-3xl text-troo-primary" />
								<p>+44 123 456 7890</p>
								<p>example@email.com</p>
							</div>
						</div>
					</motion.div>
				</div>
			</div>
		</div>
	);
};

export default FaqSection;
