import React from "react";
import Heading from "../components/Heading";
import FacilityCard from "../components/FacilityCard";
import facility1 from "../../assets/images/facility/facility-1.jpg";
import facility2 from "../../assets/images/facility/facility-2.png";
import facility3 from "../../assets/images/facility/facility-3.png";
import facility4 from "../../assets/images/facility/facility-4.png";
import facility5 from "../../assets/images/facility/facility-5.png";
import facility6 from "../../assets/images/facility/facility-6.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const FacilitySection = ({ isListingPage }) => {
	return (
		<div className="py-[100px] bg-glass-effect bg-cover bg-no-repeat bg-center w-full">
			<div className="main-container-big flex h-full gap-8 items-center flex-col">
				{!isListingPage && (
					<Heading
						headline="Our Facilities"
						title={
							<>
								Enjoy and Relax
								<br /> Luxury Hotels in City
							</>
						}
					/>
				)}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8">
					<FacilityCard
						heading="Restaurant & Bar"
						description={
							<>
								<p>Eat and Drink</p>
							</>
						}
						roomImg={facility1}
					/>
					<FacilityCard
						heading="Wellness & Spa"
						description={
							<>
								<p>Beauty and Relax</p>
							</>
						}
						roomImg={facility2}
					/>
					<FacilityCard
						heading="Health & Fitness"
						description={
							<>
								<p>Beauty and Relax</p>
							</>
						}
						roomImg={facility3}
					/>
					<FacilityCard
						heading="Wedding & Swimming Pool"
						description={
							<>
								<p>Conince</p>
							</>
						}
						roomImg={facility4}
					/>
					<FacilityCard
						heading="Meetings & Events"
						description={
							<>
								<p>Programmes</p>
							</>
						}
						roomImg={facility5}
					/>
					<FacilityCard
						heading="Tours & Excursions"
						description={
							<>
								<p>Beauty and Relax</p>
							</>
						}
						roomImg={facility6}
					/>
				</div>
				{!isListingPage && (
					<Link to={ROUTES.facilities}>
						<button className="btn-primary mt-10">View All Facilities</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default FacilitySection;
