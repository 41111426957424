import React from "react";
import Heading from "../components/Heading";

const AboutSection = () => {
	return (
		<div className="py-[100px] bg-cover bg-no-repeat bg-center w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col">
				<Heading
					headline="About Our Hotel and Resort"
					title={
						<>
							Luxury Hotel & Resort Operate The Finest
							<br /> Independent Luxury Hotel In Bali, Indonesia
						</>
					}
				/>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 text-opacity-25">
					<div className="flex flex-col items-start gap-4">
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need to be sure there isn't anything embarrassing
							hidden in the middle of text.
						</p>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable.
						</p>
					</div>
					<div className="flex flex-col items-start gap-4">
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need to be sure there.
						</p>
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage.
						</p>
					</div>
				</div>

				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 bg-troo-light shadow-xl p-8">
					<div className="flex flex-col items-center gap-2">
						<div className="relative flex flex-col items-center justify-center">
							<p className="text-8xl font-bold text-troo-secondary text-opacity-5">
								10k
							</p>
							<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-troo-secondary">
								10k
							</p>
						</div>
						<p className="text-xl text-troo-secondary">Booking Month</p>
					</div>
					<div className="flex flex-col items-center gap-2">
						<div className="relative flex flex-col items-center justify-center">
							<p className="text-8xl font-bold text-troo-secondary text-opacity-5">
								98%
							</p>
							<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-troo-secondary">
								98%
							</p>
						</div>
						<p className="text-xl text-troo-secondary">Positive feedback</p>
					</div>
					<div className="flex flex-col items-center gap-2">
						<div className="relative flex flex-col items-center justify-center">
							<p className="text-8xl font-bold text-troo-secondary text-opacity-5">
								30+
							</p>
							<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-troo-secondary">
								30+
							</p>
						</div>
						<p className="text-xl text-troo-secondary">Awards & honors</p>
					</div>
					<div className="flex flex-col items-center gap-2">
						<div className="relative flex flex-col items-center justify-center">
							<p className="text-8xl font-bold text-troo-secondary text-opacity-5">
								15+
							</p>
							<p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-troo-secondary">
								15+
							</p>
						</div>
						<p className="text-xl text-troo-secondary">World Wide Hotel</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
