import React from "react";
import Heading from "../components/Heading";
import RoomCard from "../components/RoomCard";
import room1 from "../../assets/images/room/Family-Room.jpg";
import room2 from "../../assets/images/room/Image.png";
import room3 from "../../assets/images/room/Image-1.png";
import room4 from "../../assets/images/room/Image-2.png";
import room5 from "../../assets/images/room/Image-3.png";
import room6 from "../../assets/images/room/Image-4.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const RoomSection = ({ isListingPage }) => {
	return (
		<div className="py-[100px] bg-glass-effect bg-top bg-no-repeat w-full">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col">
				{!isListingPage && (
					<Heading
						headline="Our Rooms & Suits"
						title={
							<>
								We’re Dedicated To Providing You With
								<br /> The Best Possible Experience
							</>
						}
					/>
				)}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8">
					<RoomCard
						heading="Family Room"
						description={
							<>
								<p>
									Contrary to popular belief, Lorem Ipsum is not simply random
									text. It has roots.
								</p>
								<p>1500 Sq.ft | 2 King Bed | Up to 5 Guest</p>
							</>
						}
						roomAmount="$350/night"
						roomImg={room1}
					/>
					<RoomCard
						heading="Deluxe Room"
						description={
							<>
								<p>
									Contrary to popular belief, Lorem Ipsum is not simply random
									text. It has roots.
								</p>
								<p>1500 Sq.ft | 2 King Bed | Up to 5 Guest</p>
							</>
						}
						roomAmount="$250/night"
						roomImg={room2}
					/>
					<RoomCard
						heading="Single Room"
						description={
							<>
								<p>
									Contrary to popular belief, Lorem Ipsum is not simply random
									text. It has roots.
								</p>
								<p>1500 Sq.ft | 2 King Bed | Up to 5 Guest</p>
							</>
						}
						roomAmount="$450/night"
						roomImg={room3}
					/>
					<RoomCard
						heading="VIP Room"
						description={
							<>
								<p>
									Contrary to popular belief, Lorem Ipsum is not simply random
									text. It has roots.
								</p>
								<p>1500 Sq.ft | 2 King Bed | Up to 5 Guest</p>
							</>
						}
						roomAmount="$650/night"
						roomImg={room4}
					/>
					<RoomCard
						heading="Couple Room"
						description={
							<>
								<p>
									Contrary to popular belief, Lorem Ipsum is not simply random
									text. It has roots.
								</p>
								<p>1500 Sq.ft | 2 King Bed | Up to 5 Guest</p>
							</>
						}
						roomAmount="$750/night"
						roomImg={room5}
					/>
					<RoomCard
						heading="Standard Room"
						description={
							<>
								<p>
									Contrary to popular belief, Lorem Ipsum is not simply random
									text. It has roots.
								</p>
								<p>1500 Sq.ft | 2 King Bed | Up to 5 Guest</p>
							</>
						}
						roomAmount="$1050/night"
						roomImg={room6}
					/>
				</div>
				{!isListingPage && (
					<Link to={ROUTES.roomSuits}>
						<button className="btn-primary mt-10">
							View All Rooms & Suits
						</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default RoomSection;
