import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import AdvantageSection from "../shared/sections/AdvantageSection";
import AboutSection from "../shared/sections/AboutSection";
import VideoBanner from "../shared/sections/VideoBanner";
import FacilitySection from "../shared/sections/FacilitySection";
import TestimonialSection from "../shared/sections/TestimonialSection";
import FaqSection from "../shared/sections/FaqSection";
import TeamSection from "../shared/sections/TeamSection";
import useDocumentTitle from "../Hooks/PageTitle";

const AboutUs = () => {
	useDocumentTitle("TRoo Hotel & Resort | About Page")
	return (
		<Layout banner={<SubHeroSection title="Who We Are" />}>
			<AdvantageSection />
			<AboutSection />
			<VideoBanner />
			<FacilitySection />
			<TestimonialSection />
			<FaqSection />
			<TeamSection />
		</Layout>
	);
};

export default AboutUs;
