import React from "react";
import errorImg from "../assets/images/404.svg";
import useDocumentTitle from "../Hooks/PageTitle";

const ErrorPage = () => {
	useDocumentTitle("TRoo Hotel & Resort | Error404")
	return (
		<div className="min-h-screen py-[100px] px-10 md:px-20 bg-errorPage bg-no-repeat bg-center flex flex-col items-center text-center justify-center gap-8">
			<img src={errorImg} alt="404" />
			<div className="flex flex-col items-center gap-3 text-troo-tertiary">
				<h3>Sorry! page did not found</h3>
				<p className="text-xs md:text-lg">
					The page you look for does not exist or another error occured <br />{" "}
					please kindly refreash or retun to home page.
				</p>
				<button className="btn-primary">Back to homepage</button>
			</div>
		</div>
	);
};

export default ErrorPage;
