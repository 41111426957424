import React from "react";
import logo from "./../../assets/images/logo/Logo.svg";
import { BsTelephonePlus } from "react-icons/bs";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";

const Footer = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary bg-footerBg bg-no-repeat bg-center text-troo-light">
			<div className="relative main-container-big flex h-full gap-8 items-center flex-col">
				<div className="w-full flex items-start gap-12 justify-between flex-wrap">
					<div className="flex w-full md:w-auto flex-col items-start gap-6">
						<img src={logo} alt="brand" />
						<p className="text-troo-light font-light">
							There are many variations of passages of
							<br /> Lorem Ipsum available, but the majority have
							<br /> suffered alteration in some form.
						</p>
						<h5 className="text-troo-light font-semibold">
							Ready to get started?
						</h5>
						<button className="btn-primary">Get a Quote</button>
					</div>
					<div className="flex w-full md:w-auto items-start gap-20">
						<div className="flex w-full md:w-auto flex-col items-start gap-6">
							<div className="flex flex-col items-start gap-2">
								<h4>Navigation</h4>
								<div className="bg-troo-light h-1 w-5"></div>
							</div>
							<p>About us</p>
							<p>Blog</p>
							<p>Careers</p>
							<p>Jobs</p>
							<p>In Press</p>
						</div>
						<div className="flex w-full md:w-auto flex-col items-start gap-6">
							<div className="flex flex-col items-start gap-2">
								<h4>Rooms & Suits</h4>
								<div className="bg-troo-light h-1 w-5"></div>
							</div>
							<p>Deluxe Room</p>
							<p>VIP Rooms</p>
							<p>Single Room</p>
							<p>Double Room</p>
							<p>Standard Room</p>
						</div>
					</div>
					<div className="p-8 bg-[#003069] border border-troo-primary rounded-md flex w-full md:w-auto flex-col items-start gap-6">
						<div className="flex flex-col items-start gap-2">
							<h4>Have you any questions?</h4>
							<div className="bg-troo-light h-1 w-5"></div>
						</div>

						<div className="flex items-start gap-4">
							<BsTelephonePlus className="text-troo-primary text-2xl" />
							<div className="flex flex-col items-start gap-2">
								<p>+44 123 456 7890</p>
								<p>+44 987 654 3210</p>
							</div>
						</div>
						<div className="flex items-start gap-4">
							<MdOutlineMarkEmailUnread className="text-troo-primary text-2xl" />
							<div className="flex flex-col items-start gap-2">
								<p>info@troothemes.com</p>
								<p>contact@troothemes.com</p>
							</div>
						</div>
						<div className="flex items-start gap-4">
							<HiOutlineLocationMarker className="text-troo-primary text-2xl" />
							<div className="flex flex-col items-start gap-2">
								<p>22 North Ewingar Road Lionsville,</p>
								<p>New South Wales, 2460 Australia</p>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full h-[1px] bg-troo-light" />
				<div className="flex flex-col items-center text-center gap-4">
					<h3 className="text-troo-primary">Subscribe to newsletter</h3>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem
						<br /> Ipsum has been the industry’s standard dummy tex
					</p>
					<div className="flex lg:flex-row flex-col items-center gap-4">
						<input
							placeholder="First Name"
							className="px-4 py-3 rounded-lg bg-transparent border border-troo-light outline-none border-opacity-50"
						/>
						<input
							placeholder="Enter your email"
							className="px-4 py-3 rounded-lg bg-transparent border border-troo-light outline-none border-opacity-50"
						/>
						<button className="btn-primary w-full lg:w-auto">
							Get a Quote
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
