import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import img1 from "../../assets/images/avatar/avatar-1.png";
import { BsFillStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const TestimonialCard = () => {
	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className="w-full py-8 px-8 shadow-xl bg-troo-light flex items-center gap-6 justify-between rounded-lg"
		>
			<div className="flex flex-col items-start gap-4">
				<FaQuoteLeft className="text-3xl" />
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words.
				</p>
				<div className="flex flex-col items-start">
					<h5>Jennifer Johnson</h5>
					<p className="text-sm">XYZ Company CEO</p>
				</div>
			</div>
			<div className="flex flex-col items-center gap-4">
				<div className="relative w-[100px] h-[100px] z-10 rounded-full">
					<div className="w-full h-full overflow-hidden rounded-full">
						<img src={img1} alt="img" className="w-full h-full object-cover" />
					</div>
					<div className="absolute w-full h-full bg-troo-secondary rounded-full top-2 -z-10" />
				</div>
				<div className="flex items-center gap-1 text-troo-primary rounded-lg">
					<BsFillStarFill />
					<BsFillStarFill />
					<BsFillStarFill />
					<BsFillStarFill />
					<BsFillStarFill />
				</div>
			</div>
		</motion.div>
	);
};

export default TestimonialCard;
