import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./../../assets/images/logo/Logo.svg";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { NAV_ROUTES } from "../constants/navRoutes";
import useWindowScroll from "../hooks/useWindowScroll";
import { ROUTES } from "../constants/routes";
import AccordianMenu from "./AccordianMenu";

const HeaderMenu = () => {
	const location = useLocation();
	const scrolled = useWindowScroll(52);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);
	return (
		<div
			className={`z-20 drawer flex w-full flex-col items-start ${
				scrolled
					? "bg-troo-secondary fixed top-0"
					: "bg-transparent bg-troo-secondary bg-opacity-50"
			}`}
		>
			<input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content main-container w-full flex items-center text-troo-light justify-between gap-8">
				<Link to={ROUTES.home}>
					<img src={logo} alt="Brand Logo" />
				</Link>
				<div className="hidden lg:flex w-full items-center justify-end gap-10">
					<div className="flex w-full lg:w-auto items-center gap-6 xl:gap-10 h-full ml-4 lg:ml-0 font-jost">
						{NAV_ROUTES.map((route, i) => {
							if (!route?.isMultiple) {
								return (
									<Link
										key={i}
										to={route.path}
										className={`${
											location.pathname === route.path && "activeNav"
										} relative h-full flex items-center justify-center hover:text-troo-light py-8 text-sm lg:text-base`}
									>
										{route.name}
									</Link>
								);
							} else {
								return (
									<div
										key={i}
										className="dropdown dropdown-hover h-full flex items-center py-4 text-sm lg:text-base font-jost"
									>
										<label
											tabIndex={0}
											className="flex items-center gap-2 h-full"
										>
											<span>{route.name}</span>
											<AiOutlinePlus className="text-troo-light text-xs" />
										</label>
										<ul
											tabIndex={0}
											className="dropdown-content p-0 bg-troo-light top-[100%] z-[1] menu shadow"
										>
											{route.subPages.map((subPage, i) => {
												return (
													<li
														key={i}
														className="bg-transparent text-troo-tertiary hover:bg-transparent hover:text-troo-secondary "
													>
														<Link
															to={subPage.path}
															className="hover:text-troo-primary px-6 py-3 text-sm xl:text-base whitespace-nowrap"
														>
															{subPage.name}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								);
							}
						})}
					</div>
					<div
						className="text-troo-light text-lg cursor-pointer"
						onClick={() => document.getElementById("my_modal_3").showModal()}
					>
						<BsSearch />
					</div>
					<button className="hidden 2xl:block btn-primary">
						Book Your Stay
					</button>
				</div>
				<label
					htmlFor="my-drawer-1"
					className="lg:hidden block drawer-overlay py-8"
				>
					<HiOutlineMenuAlt3 className="text-3xl text-troo-light" />
				</label>
			</div>
			<div className="drawer-side z-50">
				<label htmlFor="my-drawer-1" className="drawer-overlay"></label>
				<div className="menu p-4 overflow-scroll no-scrollbar w-[80%] md:w-80 h-full bg-troo-secondary text-troo-light">
					{/* Sidebar content here */}
					<div className="flex flex-col items-start gap-10 lg:gap-6 2xl:gap-8">
						<div className="flex items-center">
							<Link to={ROUTES.home}>
								<img src={logo} alt="trooPhotography" />
							</Link>
						</div>
						<div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
							{NAV_ROUTES.map((route, i) => {
								if (!route?.isMultiple) {
									return (
										<Link
											key={i}
											to={route.path}
											className={`py-1 ${
												location.pathname === route.path && "activeMobileNav"
											}`}
										>
											{route.name}
										</Link>
									);
								} else {
									return (
										<AccordianMenu
											key={i}
											title={route.name}
											subMenu={route.subPages}
										/>
									);
								}
							})}
						</div>
					</div>
				</div>
			</div>
			<dialog id="my_modal_3" className="modal">
				<div className="modal-box">
					<div className="w-full flex items-center gap-1">
						<input
							type="text"
							className="bg-troo-primary outline-none w-full rounded-lg h-full bg-opacity-20 px-4 py-3"
						/>
						<button className="btn-secondary">
							<BsSearch />
						</button>
					</div>
				</div>
				<form method="dialog" className="modal-backdrop">
					<button>close</button>
				</form>
			</dialog>
		</div>
	);
};

export default HeaderMenu;
