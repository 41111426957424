import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import useDocumentTitle from "../Hooks/PageTitle";

const Contact = () => {
	useDocumentTitle("TRoo Hotel & Resort | Contact-Us")
	return (
		<Layout banner={<SubHeroSection title="Get In Touch" />}>
			<ContactSection />
		</Layout>
	);
};

export default Contact;
