import React, { useState } from "react";
import { motion } from "framer-motion";

const Accordian = ({ title, desc, bgColor, srNo, isBordered }) => {
	const [isActive, setIsActive] = useState(false);

	const handleAccordionClick = (e) => {
		setIsActive(e.target.checked && !isActive);
	};

	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className={`collapse collapse-arrow cursor-pointer rounded-none ${
				isBordered && "border border-troo-secondary border-opacity-20"
			} ${
				isActive
					? "bg-troo-primary text-troo-light collapse-open"
					: `${bgColor || "bg-troo-light"} text-troo-secondary collapse-close`
			}`}
		>
			<input type="radio" name="accordian" onClick={handleAccordionClick} />
			<h6 className="collapse-title px-4 lg:px-7 flex gap-4 lg:whitespace-nowrap">
				{srNo ? `${srNo}. ${title}` : `${title}`}
			</h6>
			<div
				className={`collapse-content bg-troo-light text-troo-secondary px-7 ${
					isActive && "pt-5"
				}`}
			>
				<p>{desc}</p>
			</div>
		</motion.div>
	);
};

export default Accordian;
