import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import RoomDetailSection from "../shared/sections/RoomDetailSection";
import useDocumentTitle from "../Hooks/PageTitle";

const RoomDeails = () => {
	useDocumentTitle("TRoo Hotel & Resort | Room Details")
	return (
		<Layout banner={<SubHeroSection title="Family Room" />}>
			<RoomDetailSection />
		</Layout>
	);
};

export default RoomDeails;
