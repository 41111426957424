import React from "react";
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const RoomCard = ({ heading, description, roomAmount, roomImg }) => {
	return (
		<motion.div
			initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring", stiffness: 100 }}
			className="w-full p-6 bg-troo-light border-t-2 flex flex-col items-start gap-5 hover:border-troo-primary shadow-xl group"
		>
			<div className="w-full flex items-center justify-between">
				<h4>{heading}</h4>
				<div className="flex text-troo-primary items-center gap-1">
					<BsStarFill />
					<BsStarFill />
					<BsStarFill />
					<BsStarFill />
					<BsStarFill />
				</div>
			</div>
			{description}
			<p>
				From <span className="font-bold text-xl">{roomAmount}</span>
			</p>
			<div className="relative w-full xl:h-[420px] aspect-square overflow-hidden rounded-lg group">
				<img src={roomImg} alt="room" className="w-full h-full object-cover" />
				<motion.div
					initial={{ scale: 0.8 }}
					whileInView={{ scale: 1 }}
					transition={{ type: "spring", stiffness: 100 }}
					className="absolute top-0 left-0 bg-troo-secondary w-full h-full bg-opacity-75 hidden group-hover:flex flex-col items-center justify-center"
				>
					<Link to={ROUTES.roomSuitsDetails}>
						<button className="btn-primary">View Full Details</button>
					</Link>
				</motion.div>
			</div>
		</motion.div>
	);
};

export default RoomCard;
