import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const CopyrightFooter = () => {
	return (
		<div className="py-5 w-full bg-[#003069] text-troo-light">
			<div className="relative main-container-big flex lg:flex-row flex-col text-center gap-8 items-center justify-between">
				<p>Copyright © 2023. troothemes. All rights reserved.</p>
				<div className="flex items-center gap-3 justify-end">
					<div className="bg-troo-primary text-troo-light p-2 rounded-full">
						<FaFacebookF className="text-base" />
					</div>
					<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
						<FaInstagram className="text-base" />
					</div>
					<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
						<FaXTwitter className="text-base" />
					</div>
					<div className="hover:bg-troo-primary text-troo-light p-2 rounded-full">
						<FaLinkedinIn className="text-base" />
					</div>
				</div>
				<div className="text-troo-light flex items-center gap-4">
					<p>Terms & Conditions</p>
					<p>/</p>
					<p>Privacy Policy</p>
				</div>
			</div>
		</div>
	);
};

export default CopyrightFooter;
