import React from "react";
import Layout from "../shared/components/Layout";
import SubHeroSection from "../shared/sections/SubHeroSection";
import TeamDetailSection from "../shared/sections/TeamDetailSection";
import useDocumentTitle from "../Hooks/PageTitle";

const TeamDetails = () => {
	useDocumentTitle("TRoo Hotel & Resort | Team Details")
	return (
		<Layout banner={<SubHeroSection title="Team Details" />}>
			<TeamDetailSection />
		</Layout>
	);
};

export default TeamDetails;
